var $ = window.$ = window.jQuery = require('./lib/jquery-1.12.2.js');
var fastClick = require('./lib/fastclick-1.0.6.js');
require('./lib/bootstrap-3.3.6.js');

var colorize = require('./colorize.js');
var ui = require('./ui.js');
var inPageNav = require('./inPageNav.js');



function onDomLoad() {
  // console.log("the dom is loaded");
  fastClick(document.body);
  inPageNav.initialize();
  colorize(document.body);
  ui.initializeMenu();
  ui.initializeVideos();
}


function onWinLoad() {
  // console.log("the page has loaded");
  setTimeout(ui.hideUrlBar, 500);
}

// Add event handlers
$(onDomLoad);
$(window).load(onWinLoad);


