var $ = window.jQuery;
var _ = require('./lib/lodash.js');


function initialize() {

  var $ipn = $('.in-page-nav');

  if(!$ipn.length) return;

  var $nav = $ipn.find('nav'),
      $items = $ipn.find('.items'),
      $chapterHeadings = $('.chapter-heading'),
      pinPoint = $nav.offset().top,
      topPad = 40,
      map = [];

  $chapterHeadings.each(function() {
    var $elem = $(this);
    var $li = $('<li>').addClass('item');
    var $a = $('<a>').attr('href', '#').html($elem.html());

    $a.on('click', function(e) {
      e.preventDefault();
      $('body, html').animate({
        scrollTop: $elem.offset().top - topPad,
      }, 'fast');
      // $('body, html').scrollTop($elem.offset().top - topPad);
    });
    $li.append($a);
    $ipn.find('.items').append($li);

    map.push({
      $li: $li,
      $chapterHeading: $elem,
    });
  });

  var setSticky = function(pgScroll) {
    if(pgScroll > pinPoint) {
      $nav.addClass('pinned');
    }
    else {
      $nav.removeClass('pinned');
    }
  };

  var setActive = _.debounce(function(pgScroll) {
    var activeIndex = -1;
    map.forEach(function(mapItem, i) {
      if(pgScroll > mapItem.$chapterHeading.offset().top - topPad - 40) {
        activeIndex = i;
      }
    });

    $items.children().removeClass('active');
    if(activeIndex >= 0) $items.children().eq(activeIndex).addClass('active');
  }, 10);

  var updateNav = function() {
    var pgScroll = $(window).scrollTop();
    setSticky(pgScroll);
    setActive(pgScroll);
  };

  $(window).on('scroll', updateNav);
  updateNav();
}


module.exports = {
  initialize: initialize,
};
