
var $ = window.jQuery;


function hideUrlBar() {
  if($('html').scrollTop() > 0 || $('body').scrollTop() > 0) return;
  $('html, body').scrollTop(1);
}


function initializeMenu() {
  $('.navbar-toggle').on('click', function() {
    $(this).parents('header').toggleClass('expanded');
  });
}


function initializeVideos() {

  var videojs = window.videojs;

  $('.video').each(function(i) {
    var $videoComponent = $(this);
    var $button = $videoComponent.find('.btn');
    var $video = $videoComponent.find('video');
    var $modal = $videoComponent.find('.modal');
    var scriptURL = '//players.brightcove.net/' + $video.data('account') + '/default_default/index.min.js';
    var isLoaded = false;

    $.getScript(scriptURL, function() {
      var videoPlayer = window.videojs($video.data('id'));

      videoPlayer.on('loadedmetadata', function() {
        if(isLoaded) return;
        isLoaded = true;
        $button.on('click', function() {
          videoPlayer.play();
        });
      });

      $modal.on('click', function(event) {
        if($(event.target).parents('.video-player').length <= 0) {
          videoPlayer.pause();
        }
      });

    });
  });
}


module.exports = {
  initializeMenu: initializeMenu,
  hideUrlBar: hideUrlBar,
  initializeVideos: initializeVideos,
};


