
var $ = window.jQuery;


function applyColor($elems, colorIndex) {

  $elems.each(function() {
    var $elem = $(this);

    // add logic to skip yellow for specific classes here
    if((colorIndex === 7) && $elem.is(
      'header',
      '.content-promo-item.primary',
      '.some-other-class-here'
    )) {
      colorIndex = (colorIndex % 8) + 1;
    }

    $elem.addClass('colorize-' + colorIndex);
    colorIndex = (colorIndex % 8) + 1;
  });
  return colorIndex;
}


function colorize(container) {
  var $container = $(container);
  var colorIndex = $container.data('color-scheme') || 1;
  colorIndex = applyColor($container.find('.colorize'), colorIndex);
  colorIndex = applyColor($container.find('.colorize2'), colorIndex);
}


module.exports = colorize;
